/**
 * CardLogoList
 */

import React from 'react';
import CardLogo from 'Components/CardLogo';
import classNames from 'classnames';
import s from './CardLogoList.module.scss';

class CardLogoList extends React.PureComponent {
    render() {
        const { tag, title, items } = this.props;

        const hasHeader = Boolean(tag || title);

        const itemClasses = classNames(
            s['CardLogoList__Item'],
            {[s['CardLogoList__Item--Single']]: items && items.length === 1},
            {[s['CardLogoList__Item--Few']]: items && items.length <= 3},
        );

        return (
            <div className={s['CardLogoList']}>
                {hasHeader && (
                    <div className={s['CardLogoList__Header']}>
                        {tag && (
                            <span className={s['CardLogoList__Tag']}>{tag}</span>
                        )}
                        {title && (
                            <h2 className={s['CardLogoList__Title']}>{title}</h2>
                        )}
                    </div>
                )}

                {items && (
                    <div className={s['CardLogoList__List']}>
                        {items.map((item, index) => (
                            <div key={index} className={itemClasses}>
                                <CardLogo {...item} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default CardLogoList;
