/**
 * CardLogo
 */

import React from 'react';
import s from './CardLogo.module.scss';

const CardLogo = ({ title, url, logo}) => {

    const hasImage = Boolean(logo && logo.src);

    if (!url) {
        return (
            <div className={s['CardLogo']}>
                <div className={s['CardLogo__Link']}>
                    {hasImage && (
                        <div
                            className={s['CardLogo__Logo']}
                            style={{ backgroundImage: `url('${logo.src}')` }}
                        />
                    )}
                    {title && (<h3 className={s['CardLogo__Title']}>{title}</h3>)}
                </div>
            </div>
        );
    }

    return (
        <div className={s['CardLogo']}>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href={url}
                className={s['CardLogo__Link']}
            >
                {hasImage && (
                    <div
                        className={s['CardLogo__Logo']}
                        style={{ backgroundImage: `url('${logo.src}')` }}
                    />
                )}
                {title && (<h3 className={s['CardLogo__Title']}>{title}</h3>)}
            </a>
        </div>
    );
};

export default CardLogo;
